<template>
  <div id="app">
    <notifications/>
    <router-view/>
    <GalleryLightbox/>
  </div>
</template>
<script>
import GalleryLightbox from "@/components/Gallery/GalleryLightbox";

export default {
  components: {GalleryLightbox},
  metaInfo: {
    title: 'Travel Afrique',
    titleTemplate: '%s | Travel Afrique Kota'
  }
}
</script>
<style scoped>
body {
  font-size: 16px;
}

input, select {
  font-size: 100%;
}

</style>
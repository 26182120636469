<template>
  <div class="page">
    <BackgroundLayer v-if="showCancelOfferDialog" class="fixed z-30 top-0 left-0"/>
    <Dialog v-show="showCancelOfferDialog"
            title="Cancel offer"
            text="Are you sure you want to cancel this offer?"
            @approveDialog="submitCancelOffer"
            @cancelDialog="showCancelOfferDialog = false"
            class="dialog"
    />
    <Sidebar/>
    <main class="page-wrapper">
      <Navbar :showBackBtn="true" :showMenuBtn="false" class="hidden w-full"/>
      <section class="px-4 pb-20 mt-5 md:px-12 min-h-screen">
        <div class="wrapper">
          <Tab :tabItems="tabItems"/>
        </div>
        <template v-if="isLoadingData">
          <Loading/>
        </template>
        <template v-else>
          <div v-show="tabItems[0].isActive" class="wrapper">
            <div class="flex justify-between w-full">
              <div class="request-value">
                <div class="font-sans text-teal-base">
                  <i class="fas fa-usd-square"></i>
                  Request value
                </div>
                <span class="font-sans text-xl font-semibold text-black-base">
                  {{ tripDetails.tripCurrency }} {{ totalTripBudget }}
                </span>
              </div>
              <div class="buttons flex">
                <ButtonLink
                    v-if="showButtonLink"
                    :route="`/request/${requestId}/offer/`"
                    :text="buttonLinkText"
                    class="btn-offer"
                />

                <template v-if="['pending','draft','review','changes'].includes(tripDetails.tripRequestStatus)">
                  <template v-if="cancelOfferLoading">
                    <div class="h-11 w-32 flex items-center ml-2">
                      <Loading margin="my-0 py-0"/>
                    </div>
                  </template>
                  <template v-else>
                    <button @click="showCancelOfferDialog = true" class="btn-cancel ml-2">
                      Cancel offer
                    </button>
                  </template>
                </template>

              </div>
            </div>
            <Divider class="my-4"/>
            <div class="flex justify-between w-calc-full">
              <h6 class="font-sans text-black-lightest">Reference nr.</h6>
              <p class="mt-1 font-sans text-black-base">{{ referenceNumber }}</p>
            </div>
            <Divider class="my-4"/>
            <div class="flex justify-between w-calc-full">
              <h6 class="font-sans text-black-lightest">
                Language of communication
              </h6>
              <p class="mt-1 font-sans text-black-base">
                {{ tripDetails.tripLanguage }}
              </p>
            </div>
            <Divider class="my-4"/>
            <div class="flex justify-between mt-3 w-calc-full">
              <h6 class="font-sans text-black-lightest">Salutation</h6>
              <p class="mt-1 font-sans text-black-base">
                {{ tripDetails.tripSalutation }}
              </p>
            </div>
            <Divider class="my-4"/>
            <div class="flex justify-between mt-3 w-calc-full">
              <h6 class="font-sans text-black-lightest">First name</h6>
              <p class="mt-1 font-sans text-black-base">
                {{ tripDetails.tripFirstname }}
              </p>
            </div>
            <Divider class="my-4"/>
            <div class="flex justify-between mt-3 w-calc-full">
              <h6 class="font-sans text-black-lightest">Last name</h6>
              <p class="mt-1 font-sans text-black-base">
                {{ tripDetails.tripLastname }}
              </p>
            </div>
            <Divider class="my-4"/>
            <div class="flex justify-between mt-3 w-calc-full">
              <h6 class="font-sans text-black-lightest">Request date</h6>
              <p class="mt-1 font-sans text-black-base">
                <template v-if="tripDetails.createdAt">
                  <timeago
                      :auto-update="60"
                      :datetime="tripDetails.createdAt"
                  >
                  </timeago>
                </template>
              </p>
            </div>
            <Divider class="my-4"/>
            <div class="flex justify-between mt-3 w-calc-full">
              <h6 class="font-sans text-black-lightest">Phone nr.</h6>
              <p class="mt-1 font-sans text-black-base">
                {{ tripDetails.tripPhone }}
              </p>
            </div>
            <Divider class="my-4"/>
            <div class="flex justify-between mt-3 w-calc-full">
              <h6 class="font-sans text-black-lightest">Email</h6>
              <p class="mt-1 font-sans text-black-base">
                {{ tripDetails.tripEmail }}
              </p>
            </div>
            <Divider class="my-4"/>
            <div class="flex justify-between mt-3 w-calc-full">
              <h6 class="font-sans text-black-lightest">Destination</h6>
              <p class="mt-1 font-sans text-black-base">
                {{ tripDetails.tripDestination }}
              </p>
            </div>
            <Divider class="my-4"/>
            <div class="flex justify-between mt-3 w-calc-full">
              <h6 class="font-sans text-black-lightest">Trip date</h6>
              <p class="mt-1 font-sans text-black-base">
                {{ tripDetails.tripDateKnown ? readableDateRange(tripDetails.tripDates) : tripDetails.tripDates }}
              </p>
            </div>

            <Divider class="my-4"/>
            <div class="flex justify-between mt-3 w-calc-full">
              <h6 class="font-sans text-black-lightest">Group</h6>
              <p class="mt-1 font-sans text-black-base">
                {{ tripDetails.tripGroupType }}
              </p>
            </div>
            <Divider class="my-4"/>
            <div class="flex justify-between mt-3 w-calc-full">
              <h6 class="font-sans text-black-lightest">Budget per traveler</h6>
              <p class="mt-1 font-sans text-black-base">
                {{ tripDetails.tripCurrency }} {{ tripDetails.tripBudget }}
              </p>
            </div>
            <Divider class="my-4"/>
            <div class="flex justify-between mt-3 w-calc-full">
              <h6 class="mt-4 font-sans text-black-lightest">Trip type</h6>
              <p class="mt-1 font-sans text-black-base">
                {{ tripDetails.tripReason }}
              </p>
            </div>
            <Divider class="my-4"/>
            <div class="row-container">
              <h6 class="mt-4 row-title">Number of travelers</h6>
            </div>
            <div class="flex-wrap row-container">
              <div class="w-full travelers-details">
                <p class="py-2 font-sans text-black-base">
                  {{ tripDetails.tripAdults }} × Adults
                </p>

                <div class="container flex flex-wrap justify-between">
                  <p class="py-1 font-sans text-black-base">
                    {{ tripDetails.tripChildren }} × Children
                  </p>
                  <div class="text-sm tex-grey-base text-black-lightest">
                    <template v-if="tripDetails.tripChildrenAge">
                      <span
                          v-for="(
                          age, index
                        ) in tripDetails.tripChildrenAge.split(',')"
                          :key="index"
                          class="age-label"
                      >
                        {{ age }} {{ age != 1 ? "years" : "year" }}
                      </span>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <Divider class="my-4"/>
            <div class="mt-3 w-calc-full">
              <h6 class="font-sans text-black-lightest">Experiences</h6>
              <p class="mt-1 font-sans text-black-base">
                {{
                  tripDetails.originalExperiences || "No experiences selected"
                }}
              </p>
            </div>
            <template
                v-if="
                tripDetails.originalAccommodation &&
                tripDetails.originalAccommodation !== 'null'
              "
            >
              <Divider class="my-4"/>
              <div class="mt-3 w-calc-full">
                <h6 class="font-sans text-black-lightest">Accommodation</h6>
                <p class="mt-1 font-sans text-black-base">
                  {{ tripDetails.originalAccommodation }}
                </p>
              </div>
            </template>

            <Divider class="my-4"/>
            <div class="flex justify-between mt-3 w-calc-full">
              <h6 class="font-sans text-black-lightest">Meal plan</h6>
              <p class="mt-1 font-sans text-black-base">
                {{ tripDetails.tripMealPlan ? tripDetails.tripMealPlan : 'Not set' }}
              </p>
            </div>
            <Divider class="my-4"/>
            <div class="flex justify-between mt-3 w-calc-full">
              <h6 class="font-sans text-black-lightest">Consulting</h6>
              <p class="mt-1 font-sans text-black-base">
                {{ tripDetails.tripConsultation === 1 ? "Yes" : "No" }}
              </p>
            </div>
            <template
                v-if="
                tripDetails.tripDetails && tripDetails.tripDetails !== 'null'
              "
            >
              <Divider class="my-4"/>
              <div class="mt-3 w-calc-full">
                <h6 class="font-sans text-black-lightest">
                  Additional details
                </h6>
                <p class="mt-1 font-sans text-black-base">
                  {{ tripDetails.tripDetails }}
                </p>
              </div>
            </template>
          </div>
          <div
              v-show="tabItems[1].isActive"
              id="requested-changes"
              class="wrapper"
          >
            <EmptyStateFrameless
                v-if="showEmptyState"
                text="The customer hasn't requested any changes yet."
                title="No change requests yet"
            />

            <MessageItem
                v-for="(changeRequest, i) in changeRequests"
                :key="i"
                :customerName="`${tripDetails.tripFirstname} ${tripDetails.tripLastname}`"
                :dateTime="changeRequest.createdAt"
                :message="changeRequest.message"
                :messageType="changeRequest.messageType"
            />
          </div>

          <div v-show="tabItems[2].isActive" id="preview-offer" class="wrapper">
            <OfferSummary v-if="showOfferSummary" :offer="offer" :dates="offer.tripDates"/>
            <EmptyStateFrameless
                v-if="!showOfferSummary"
                text="Create an offer to display a preview here."
                title="No offer created yet"
            />
            <ButtonLink
                v-if="showButtonLink"
                :route="`/request/${requestId}/offer/`"
                :text="buttonLinkText"
                class="empty-state-action"
            />
          </div>
        </template>
      </section>
    </main>
    <nav class="bottom-bar">
      <ButtonLink
          v-if="showButtonLink"
          :route="`/request/${requestId}/offer/`"
          :text="buttonLinkText"
      />
    </nav>
  </div>
</template>
<script>
import ButtonLink from "@/components/Buttons/ButtonLink";
import Divider from "@/components/Layout/Divider";
import EmptyStateFrameless from "@/components/EmptyStates/EmptyStateFrameless";
import MessageItem from "@/components/Offer/MessageItem";
import Navbar from "@/components/Layout/Navbar";
import Sidebar from "@/components/Layout/Sidebar/Sidebar";
import Tab from "@/components/Tabs/Tab";
import Loading from "@/components/Preloaders/Loading";
import OfferSummary from "@/components/Offer/OfferSummary";
import {mapActions, mapGetters} from "vuex";
import BackgroundLayer from "../../components/Layout/BackgroundLayer";
import Dialog from "../../components/Dialogs/Dialog";
import {humanReadableDateRange} from "../../shared/dateHandler";

export default {
  name: "Requests",
  components: {
    Dialog,
    BackgroundLayer,
    ButtonLink,
    Divider,
    EmptyStateFrameless,
    Loading,
    MessageItem,
    Navbar,
    OfferSummary,
    Sidebar,
    Tab,
  },
  props: {
    requestId: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      currency: "USD",
      region: "en-EN",
      offerResponse: {},
      showCancelOfferDialog: false,
      cancelOfferLoading: false,
      tabItems: [
        {
          name: "Request details",
          isActive: true,
          textColor: "text-black-base",
        },
        {
          name: "Requested changes",
          isActive: false,
          textColor: "text-grey-dark",
        },
        {
          name: "Offer Summary",
          isActive: false,
          textColor: "text-grey-dark",
        },
      ],
      pageIsLoading: false,
    };
  },
  methods: {
    ...mapActions({
      getTripRequestDetails: "offer/getTripRequestDetails",
      cancelOffer: "offer/cancelOffer"
    }),
    readableDateRange(dates) {
      if (dates) return humanReadableDateRange(dates)
    },
    formatNumber(value, currency, region) {
      value = new Intl.NumberFormat(region, {
        style: "currency",
        currency: currency,
      }).format(value);
      return value;
    },
    submitCancelOffer() {
      this.cancelOfferLoading = true;
      this.showCancelOfferDialog = false;
      this.cancelOffer(this.$route.params.requestId)
          .then(({data}) => {

            this.$notify({
              type: "success",
              title: `${data.response}`,
              text: `${data.message}`,
            });
            this.$router.push({name: "Requests"});
          })
          .catch((error) => {
            this.cancelOfferLoading = false;
            this.$notify({
              type: "error",
              title: `${error.message}`,
              text: `${error.response.data.message}`,
            });
          });
    },
  },
  beforeMount() {
    this.getTripRequestDetails(this.requestId);
  },

  computed: {
    ...mapGetters({
      offerDetails: "offer/offerDetails",
      tripDetails: "offer/tripDetails",
      showOfferSummary: "offer/showOfferSummary",
      changeRequests: "offer/requestedChanges",
      isLoadingData: "offer/isLoadingData",
    }),
    referenceNumber() {
      if (
          ["pending", "changes", "draft"].includes(this.tripDetails.tripRequestStatus)
      ) {
        return this.tripDetails.tripRequestId;
      } else {
        return this.tripDetails.id;
      }
    },
    buttonLinkText() {
      if (
          ["pending", "review", "changes"].includes(this.tripDetails.tripRequestStatus)
      ) {
        return "Edit offer";
      } else if (["draft"].includes(this.tripDetails.tripRequestStatus)) {
        return "Edit draft";
      } else {
        return "Create offer";
      }
    },
    showButtonLink() {
      return ["pending", "review", "changes", "draft", "open"].includes(
          this.tripDetails.tripRequestStatus
      );
    },
    showEmptyState() {
      if (!this.changeRequests.length) {
        return true;
      }
      return false;
    },
    requestValue() {
      let budgetPerTraveler = this.budgetPerPerson;
      let leadValue = budgetPerTraveler * this.numberOfTravelers;
      return this.formatNumber(leadValue, this.currency, this.region);
    },
    numberOfTravelers() {
      let total = this.travelers.adults + this.travelers.children.number;
      return total;
    },
    childrenAges() {
      return this.travelers.children.ages;
    },
    totalTripBudget() {
      return (
          (this.tripDetails.tripAdults + this.tripDetails.tripChildren) *
          this.tripDetails.tripBudget
      ).toLocaleString();
    },
    travelersDetailed() {
      let numberOfAdults = this.travelers.adults; //Number of adult travelers
      let adultsWord =
          numberOfAdults > 1 || numberOfAdults == 0 ? "adults" : "adult"; //Define whether "adult" is written in the plural or singular form
      let numberOfChildren = this.travelers.children; //Number of child travelers
      let childrenWord =
          numberOfChildren > 1 || numberOfChildren == 0 ? "children" : "child"; //Define whether "child" is written in the plural or singular form
      let numberOfInfants = this.travelers.infants; //Number of infant travelers
      let infantsWord =
          numberOfInfants > 1 || numberOfInfants == 0 ? "infants" : "infant"; //Define whether "infant" is written in the plural or singular form
      let detailedSentence = `${numberOfAdults} ${adultsWord}, ${numberOfChildren} ${childrenWord}, ${numberOfInfants} ${infantsWord}`;
      return detailedSentence;
    },
    budgetPerTraveler() {
      return this.formatNumber(
          this.budgetPerPerson,
          this.currency,
          this.region
      );
    },

    offer() {
      function compare(a, b) {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      }

      //sort itinerary list
      let sortedItineraryDetails = this.tripDetails.tripItinerary;

      sortedItineraryDetails = sortedItineraryDetails.sort(compare);
      //sort accommodation list
      let sortedAccommodationDetails =
          this.tripDetails.tripAccommodationDetails;

      sortedAccommodationDetails = sortedAccommodationDetails.sort(compare);
      let details = {...this.tripDetails};
      details.tripAccommodationDetails = sortedAccommodationDetails;
      details.tripItinerary = sortedItineraryDetails;

      details.tripAgencies = [];

      if (this.tripDetails.tripAgencies) {
        if (typeof this.tripDetails.tripAgencies === "string") {
          details.tripAgencies = this.tripDetails.tripAgencies
              .split(",")
              .map((x) => parseInt(x));
        } else if (typeof this.tripDetails.tripAgencies === "object") {
          details.tripAgencies = this.tripDetails.tripAgencies.map((x) =>
              parseInt(x)
          );
        }
      }
      return details;
    },
  },
};
</script>

<style scoped>
.page {
  @apply flex overflow-hidden bg-grey-light h-100vh;
}

.page-wrapper {
  @apply relative self-start w-full h-full overflow-y-auto pb-12;
}

.row-title {
  @apply font-sans text-black-lightest;
}

.age-label {
  @apply inline-block p-1.5 px-3 bg-black-darker text-white rounded-md mr-1 mb-1;
}

.wrapper {
  @apply relative max-w-3xl mt-12 transform -translate-x-1/2 left-1/2;
}

.empty-state-action {
  @apply hidden sm:flex mt-12;
}

.btn-offer {
  @apply w-32 hidden sm:flex;
}

.bottom-bar {
  @apply w-full fixed sm:hidden bg-white p-3 bottom-0 border-t border-grey-light;
}

.btn-cancel {
  @apply w-32 h-11;
  @apply bg-white shadow-sm rounded focus:outline-none focus:scale-95;
  @apply transition duration-300 ease-in-out;
  @apply focus:ring-offset-2 focus:ring-2 focus:ring-red-dark focus:border-red-dark;
  @apply hover:bg-red-base hover:text-white;
  @apply text-red-base;
}

.dialog {
  @apply fixed z-40 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
}

</style>

<template>
  <div class="page">
    <Sidebar/>
    <main class="page-main">
      <Navbar
          :showBackBtn="true"
          :showMenuBtn="false"
          class="w-full"
          route="/customers"
      />
      <section class="page-section">
        <div class="page-tab-container">
          <TabButtons :buttons="mainTabItems"/>
        </div>

        <template v-if="isLoadingData">
          <Loading/>
        </template>

        <template v-else>
          <div v-if="mainTabItems[0].isActive" id="details" class="mt-28">
            <div class="wrapper">
              <div class="customer-table">
                <div class="request-value">
                  <div class="font-sans text-teal-base">
                    <i class="fas fa-address-card"></i> ID
                  </div>
                  <span class="font-sans text-black-base font-semibold text-xl">
                    {{ customer.id }}
                  </span>
                </div>
              </div>
              <Divider class="my-4"/>
              <div class="flex justify-between w-calc-full mt-3">
                <h6 class="font-sans text-black-lightest">Name</h6>
                <p class="font-sans text-black-base mt-1">
                  {{ customer.name }}
                </p>
              </div>

              <template v-if="customer.createdAt">
                <Divider class="my-4"/>
                <div class="flex justify-between w-calc-full mt-3">
                  <h6 class="font-sans text-black-lightest">Customer since</h6>
                  <p class="font-sans text-black-base mt-1">
                    <timeago
                        :auto-update="60"
                        :datetime="customer.createdAt"
                    ></timeago>
                  </p>
                </div>
              </template>

              <Divider class="my-4"/>
              <div class="flex justify-between w-calc-full mt-3">
                <h6 class="font-sans text-black-lightest">Phone nr.</h6>
                <p class="font-sans text-black-base mt-1">
                  {{ customer.phone }}
                </p>
              </div>
              <Divider class="my-4"/>
              <div class="flex justify-between w-calc-full mt-3">
                <h6 class="font-sans text-black-lightest">Email</h6>
                <p class="font-sans text-black-base mt-1">
                  {{ customer.email }}
                </p>
              </div>
            </div>
          </div>

          <div v-if="mainTabItems[1].isActive" id="requests" class="mt-28">
            <div v-if="selectedCustomerTripRequests">
              <div v-if="selectedCustomerTripRequests.length > 0">
                <div class="hidden sm:block">
                  <Table
                      :columns="[
                      'Destination',
                      'Reference Nr.',
                      'Name',
                      'Request value',
                      'Date',
                      'Status',
                    ]"
                      :showId="false"
                      class=""
                  >
                    <div
                        v-for="(request, index) in selectedCustomerTripRequests"
                        :key="index"
                    >

                      <router-link
                          :to="{
                          name: 'CustomerRequest',
                          params: { customerId: request.userId,  requestId: request.id },
                        }"
                      >
                        <div :key="index" class="row-wrap">
                          <div class="table-column table-column-sb">
                            {{ request.tripDestination }}
                          </div>

                          <div class="table-column">
                            {{ request.id }}
                          </div>

                          <div class="table-column">
                            {{ request.tripFirstname }}
                            {{ request.tripLastname }}
                          </div>

                          <div class="table-column">
                            {{ requestBudget(request) }}
                          </div>

                          <div class="table-column">
                            <timeago
                                :auto-update="60"
                                :datetime="request.createdAt"
                            ></timeago>
                          </div>

                          <div class="table-column">
                            <span :class="[statusStyle(request)]">
                              {{ request.tripRequestStatus }}
                            </span>
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </Table>
                </div>

                <!--Responsive block-->
                <div
                    v-for="(request, index) in selectedCustomerTripRequests"
                    :key="index"
                    class="t-row"
                    @click.prevent="selectRequest(index)"
                >
                  <div class="row-mb">
                    <div class="w-full">
                      <div class="row-mb-header">
                        <timeago
                            :auto-update="60"
                            :datetime="request.createdAt"
                            class="row-mb-date"
                        >
                        </timeago>
                        <div class="status-container-mb">
                          <span :class="statusStyle(request)">
                            {{ request.tripRequestStatus }}
                          </span>
                        </div>
                      </div>
                      <div class="row-mb-name">
                        {{ request.tripFirstname }} {{ request.tripLastname }}
                      </div>
                      <div class="row-mb-text">
                        {{ request.tripDestination }}
                      </div>
                      <div class="row-mb-text">
                        {{ requestBudget(request) }} / traveler
                      </div>
                    </div>
                    <div class="row-mb-icon-container">
                      <i
                          class="bx bx-chevron-right bx-sm absolute bottom-0"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>

              <template v-else>
                <div class="empty-state w-full">
                  <figure
                      class="
                      max-w-lg
                      relative
                      left-1/2
                      transform
                      -translate-x-1/2
                    "
                  >
                    <img
                        alt="Empty State"
                        class="w-full h-full object-cover"
                        src="@/assets/img/empty-states/no-change-requests.svg"
                    />
                  </figure>
                  <h3
                      class="
                      font-sans
                      text-black-base text-xl
                      font-semibold
                      text-center
                      mt-6
                    "
                  >
                    No new requests yet
                  </h3>
                  <p class="font-sans text-black-lighter text-center">
                    There are no new requests from this customer yet.
                  </p>
                </div>
              </template>
            </div>
          </div>
        </template>
      </section>
    </main>
  </div>
</template>

<script>
import Divider from "@/components/Layout/Divider";
import Navbar from "@/components/Layout/Navbar";
import Sidebar from "@/components/Layout/Sidebar/Sidebar";
import TabButtons from "@/components/Tabs/TabButtons";
import Table from "@/components/Table/Table";
import Loading from "@/components/Preloaders/Loading";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Customer",
  components: {
    Loading,
    Divider,
    Navbar,
    Sidebar,
    TabButtons,
    Table,
  },
  data() {
    return {
      region: "en-EN",
      mainTabItems: [
        {
          name: "Details",
          isActive: true,
        },
        {
          name: "Requests",
          isActive: false,
        },
      ],
      travelers: {
        adults: 2,
        children: 3,
        infants: 2,
      },
      selectedRequest: {},
      requestSheetPosition: "-bottom-full",
      showOverlay: false,
      showSheet: false,
      requests: [],
      pageIsLoading: true,
    };
  },
  methods: {
    ...mapActions({
      getSelectedCustomerAndTripRequests:
          "customers/getSelectedCustomerAndTripRequests",
    }),
    closeBottomSheet() {
      this.requestSheetPosition = "-bottom-full";
      this.showDialog = this.showOverlay = this.showSheet = false;
    },
    statusStyle(request) {
      let status = request.tripRequestStatus;

      if (status == "pending") return "status status-pending";

      if (status == "open") return "status status-open";

      if (status == "draft") return "status status-draft";

      if (status == "completed") return "status status-completed";

      if (status == "changes") return "status status-changes";

      return "status status-cancelled";
    },
    requestBudget(request) {
      let currency = request.tripCurrency;
      let budgetPerPerson = request.tripBudget;
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency,
      }).format(budgetPerPerson);
    },
    openBottomSheet() {
      this.requestSheetPosition = "bottom-0";
      this.showOverlay = this.showSheet = true;
      //Scroll content of request sheet to the top
      let container = this.$el.querySelector("#requestsheet");
      container.scrollTo(0, 0);
    },
    selectRequest(index) {
      this.selectedRequest = this.requests[index];
      this.openBottomSheet();
    },
  },
  beforeMount() {
    this.getSelectedCustomerAndTripRequests(this.$route.params.id);
  },
  computed: {
    showEmptyState() {
      if (!this.requests) {
        return true;
      }
      return false;
    },

    ...mapGetters({
      customer: "customers/selectedCustomer",
      selectedCustomerTripRequests: "customers/selectedCustomerTripRequests",
      isLoadingData: "customers/isLoadingData",
    }),
  },
};
</script>
<style scoped>
.page {
  @apply flex bg-grey-light h-100vh overflow-hidden;
}

.page-main {
  @apply self-start w-full relative h-full overflow-y-auto;
}

.page-section {
  @apply px-4 md:px-12 mt-5 pb-20;
}

.page-tab-container {
  @apply flex justify-center w-full;
}

.customer-table {
  @apply flex justify-between w-full;
}

.t-row {
  @apply relative w-full cursor-pointer grid sm:hidden;
  @apply w-full mb-4;
  @apply bg-white shadow-sm;
  @apply transition duration-300 rounded;
}

.row-mb {
  @apply sm:hidden w-full flex justify-between p-4;
}

.row-mb-header {
  @apply inline-flex items-center;
}

.row-mb-date {
  @apply mr-3 text-xs text-black-lighter;
}

.row-mb-name {
  @apply font-semibold leading-8;
}

.row-mb-reference {
  @apply text-sm text-black-lighter leading-5;
}

.row-mb-text {
  @apply text-sm leading-5;
}

.row-mb-icon-container {
  @apply relative w-7 h-full;
}

.status-container-mb {
  @apply ml-auto;
}

.status {
  @apply text-xs font-sans inline-block px-2 py-1 rounded-full;
}

.status-pending {
  @apply text-yellow-text bg-yellow-light;
}

.status-open {
  @apply text-teal-text bg-teal-light;
}

.status-completed {
  @apply text-green-text bg-green-light;
}

.status-cancelled {
  @apply text-red-text bg-red-light;
}

.status-changes {
  @apply text-violet-text bg-violet-light;
}

.status-draft {
  @apply text-black-base bg-grey-base;
}

.row-wrap {
  @apply cursor-pointer grid grid-flow-col grid-cols-12 gap-4 mt-1 h-14 px-6 w-full bg-white shadow-sm hover:shadow-md transition duration-300 rounded;
}

.table-column {
  @apply text-sm font-sans font-normal text-black-base flex items-center col-span-2;
}

.table-column-sb {
  @apply font-semibold;
}

.wrapper {
  @apply max-w-3xl relative left-1/2 transform -translate-x-1/2;
}

.bottom-bar {
  @apply bg-white w-full py-3 border-t border-grey-light fixed bottom-0 left-0 flex justify-center;
}

.sheet {
  @apply h-100vh fixed w-full z-10 transition-all duration-700 ease-in-out;
}

.dialog {
  @apply fixed z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
}

.bottom-bar-wrapper {
  @apply flex max-w-5xl w-full;
}
</style>
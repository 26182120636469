<template>
  <div>
    <h3 class="pricing-table-title">Total</h3>
    <div class="row border-t border-grey-base">
      <div class="col">
        {{ adultsPriceCalc }}
      </div>
      <div class="col text-right">
        {{ adultsTotalPrice }}
      </div>
    </div>
    <div class="row flex border-t border-grey-base">
      <div class="col">
        {{ childrenPriceCalc }}
      </div>
      <div class="col text-right">
        {{ childrenTotalPrice }}
      </div>
    </div>
    <div class="row border-b border-t border-grey-base">
      <div class="col">
        {{ singleRoomsCalc }}
      </div>
      <div class="col text-right">
        {{ singleRoomsTotal }}
      </div>
    </div>
    <div class="row border-b border-grey-base">
      <div class="col">{{ earningFeesCalc }}</div>
      <div class="col text-right">
        {{ earningFeesTotal }}
      </div>
    </div>
    <div class="row">
      <div class="col font-semibold">Total price</div>
      <div class="col text-right">
        <div>{{ totalPriceBase }}</div>

        <div v-for="price in totalPriceOthers" :key="price">
          <div>{{ price }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TotalPriceSummary",
  props: {
    tripDetails: {
      type: Object,
      required: true,
    },
    selectedCurrencyRates: {
      type: Object,
      required: false,
    },
  },
  methods: {
    formatNumber(value, currency, region) {
      value = new Intl.NumberFormat(region, {
        style: "currency",
        currency: currency || "USD",
      }).format(value);
      return value;
    },
  },
  computed: {
    pricePerChild() {
      let total = this.tripDetails.baseTripPricePerChild;
      return this.formatNumber(
        total,
        this.tripDetails.tripCurrency,
        this.region
      );
    },
    childrenPriceCalc() {
      let isPlural =
        this.tripDetails.tripChildren > 1 || this.tripDetails.tripChildren == 0;
      let price = this.pricePerChild || 0;
      let numOfAChildren = this.tripDetails.tripChildren || 0;
      return `${price} x ${numOfAChildren} ${isPlural ? "children" : "child"}`;
    },
    singleRoomsCalc() {
      let isPlural =
        this.tripDetails.tripSingleRooms > 1 ||
        this.tripDetails.tripSingleRooms == 0;
      let price = this.singleRoomPriceFormatted || 0;
      let numOfRooms = this.tripDetails.tripSingleRooms || 0;
      return `${price} x ${numOfRooms} single ${isPlural ? "rooms" : "room"}`;
    },
    childrenTotalPrice() {
      let total =
        this.tripDetails.baseTripPricePerChild * this.tripDetails.tripChildren;
      return this.formatNumber(
        total,
        this.tripDetails.tripCurrency,
        this.region
      );
    },
    numberOfTravelers() {
      return this.tripDetails.tripChildren + this.tripDetails.tripAdults;
    },
    priceFormatted() {
      let total = this.tripDetails.tripBudget;
      return this.formatNumber(
        total,
        this.tripDetails.tripCurrency,
        this.region
      );
    },
    singleRoomPriceFormatted() {
      let total = this.tripDetails.baseTripSingleRoomPrice;
      return this.formatNumber(
        total,
        this.tripDetails.tripCurrency,
        this.region
      );
    },
    pricePerAdult() {
      let total = this.tripDetails.baseTripPricePerAdult;
      return this.formatNumber(
        total,
        this.tripDetails.tripCurrency,
        this.region
      );
    },
    adultsPriceCalc() {
      let isPlural =
        this.tripDetails.tripAdults > 1 || this.tripDetails.tripAdults == 0;
      let price = this.pricePerAdult || 0;
      let numOfAdults = this.tripDetails.tripAdults || 0;
      return `${price} x ${numOfAdults} ${isPlural ? "adults" : "adult"}`;
    },
    earningFeesCalc() {
      let isPlural =
        this.tripDetails.tripEarningFees > 1 ||
        this.tripDetails.tripEarningFees == 0;
      let percentage = this.tripDetails.tripEarningFees || 0;
      return `${percentage}% earning ${isPlural ? "fees" : "fee"}`;
    },
    adultsTotalPrice() {
      let total =
        this.tripDetails.baseTripPricePerAdult * this.tripDetails.tripAdults;
      return this.formatNumber(
        total,
        this.tripDetails.tripCurrency,
        this.region
      );
    },
    singleRoomsTotal() {
      let total =
        this.tripDetails.baseTripSingleRoomPrice * this.tripDetails.tripSingleRooms;
      return this.formatNumber(
        total,
        this.tripDetails.tripCurrency,
        this.region
      );
    },

    earningFeesTotal() {
      let adultsTotalPrice =
        this.tripDetails.baseTripPricePerAdult * this.tripDetails.tripAdults;
      let childrenTotalPrice =
        this.tripDetails.baseTripPricePerChild * this.tripDetails.tripChildren;
      let totalTravelersPrice = childrenTotalPrice + adultsTotalPrice;
      let earningFees =
        (totalTravelersPrice * this.tripDetails.tripEarningFees) / 100;
      return this.formatNumber(
        earningFees,
        this.tripDetails.baseTripCurrency,
        this.region
      );
    },
    totalPrice() {
      let adultsTotalPrice =
        this.tripDetails.baseTripPricePerAdult * this.tripDetails.tripAdults;
      let childrenTotalPrice =
        this.tripDetails.baseTripPricePerChild * this.tripDetails.tripChildren;
      let totalTravelersPrice = childrenTotalPrice + adultsTotalPrice;
      let roomPrice =
        this.tripDetails.baseTripSingleRoomPrice * this.tripDetails.tripSingleRooms;
      let earningFees =
        (totalTravelersPrice * this.tripDetails.tripEarningFees) / 100;
      return totalTravelersPrice + earningFees + roomPrice;
    },
    totalPriceBase() {
      let total = this.totalPrice;
      return this.formatNumber(
        total,
        this.tripDetails.baseTripCurrency,
        this.region
      );
    },
    totalPriceOthers() {
      let total = this.totalPrice;
      const totalprices = {};
      if (this.selectedCurrencyRates) {
        Object.entries(this.selectedCurrencyRates).forEach(([key, value]) => {
          if (key !== this.tripDetails.baseTripCurrency.toLowerCase()) {
            totalprices[key] = this.formatNumber(
              total * value,
              key,
              this.region
            );
          }
        });
        return totalprices;
      }
      return {};
    },
  },
};
</script>

<style scoped>
.pricing-table {
  @apply top-24;
  @apply max-w-screen-sm left-1/2 transform -translate-x-1/2 w-full relative;
}

.pricing-table-title {
  @apply font-sans font-semibold text-base sm:text-xl mb-3;
}

.row {
  @apply flex justify-between py-2;
  @apply text-sm sm:text-base;
}

.col {
  @apply w-1/2;
}
</style>
